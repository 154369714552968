.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.marquee {
  height: 100px;
  background-color: #008aff;
  overflow: hidden; /* Hide overflow */
  position: relative; /* Position for animation */
}

.marquee-content {
  display: flex;
  animation: marquee 10s linear infinite; /* Animation settings */
}

.marquee p {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin: 13px 50px; /* Add space between items */
  white-space: nowrap; /* Prevent wrapping */
}

@keyframes marquee {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* End at the left */
  }
}

/* Stats Section Container */
.stats-section {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4; /* Light background color for contrast */
}

/* Individual Stat Item */
.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px; /* Space between stat items */
}

/* Stat Icon Styling */
.stat-icon {
  font-size: 50px; /* Size of the icon */
  color: #4caf50; /* Icon color, can be changed */
  margin-bottom: 10px; /* Space between icon and number */
}

/* Stat Number Styling */
.stat-number {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Dark color for the number */
  margin-bottom: 5px;
}

/* Stat Title Styling */
.stat-title {
  font-size: 16px;
  color: #777; /* Lighter color for the text */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .stats-section {
    flex-direction: column;
    align-items: center;
  }

  .stat-item {
    margin-bottom: 20px;
  }
}
