a {
  color: #008aff;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  flex-direction: row;
}
.site-logo img {
  height: 55px;
}
.home-contact {
  background-color: #008aff;
  color: white;
}

.home-contact p {
  font-size: 18px;
  margin-bottom: 0rem;
}
.home-contact:hover {
  background-color: #008aff;
  color: black;
}
.home-contact p:hover {
  font-size: 18px;
  margin-bottom: 0rem;
  color: black;
}
.header-booknow {
  background-color: #008aff;
  color: white;
  font-size: 18px;
  margin-bottom: 0rem;
}
.header-booknow p {
  font-size: 18px;
  margin-bottom: 0rem;
  color: white;
  text-transform: none;
}
.header-booknow p:hover {
  font-size: 18px;
  margin-bottom: 0rem;
  color: #008aff;
}
.header-booknow:hover {
  color: #008aff;
  background-color: white;

  border: 1px solid #008aff;
}

.header-service {
  background-color: white;
  color: #008aff;
  border: 1px solid #008aff;
}
.header-service p {
  font-size: 18px;
  margin-bottom: 0rem;
  color: #008aff;
}
.header-service p:hover {
  font-size: 18px;
  margin-bottom: 0rem;
  color: #008aff;
  text-transform: none;
}
.header-service:hover {
  background-color: #008aff;
  color: #008aff;
}

.product_card {
  width: 280px;
  height: 400px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
  transition: 0.3s;
  border: 2px solid rgb(236, 236, 236);
  position: relative;
}

.product_card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.product_card .product_info {
  padding: 10px;
  font: 14px "Poppins", sans-serif;
}

.colors {
  display: flex;
  gap: 5px;
  margin: 5px 0;
  align-items: center;
}

.colors .color {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}

.colors .color:nth-child(1) {
  border: 2px solid rgb(252, 252, 252);
  box-shadow: 0 0 0 1px rgba(37, 37, 37, 0.505);
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.bottom .price {
  font-size: 20px;
  font-weight: 600;
}

.bottom .add_to_cart {
  padding: 8px 15px;
  border-radius: 20px;
  border: none;
  background: #fff;
  color: #111111;
  font-size: 14px;
  cursor: pointer;
  border: 3px solid rgba(0, 0, 0, 0.17);
  transition: 0.3s ease;
}

.add_to_cart:hover {
  background: #111111b8;
  color: #fff;
}
.add_to_wishlist {
  position: absolute;
  padding: 5px;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #ffffff9b;
  color: #111111;
  border: none;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.product_description {
  font-size: 14px;
  color: #292828;
  margin-top: 5px;
}
#productpage {
  margin-top: 130px;
}
#productpage-web {
  margin-top: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.product-header {
  background-color: #ff6347;
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.product-main {
  flex: 1;
  padding: 2rem;
}

.product-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.product-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  margin: 1rem;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.product-card-content {
  padding: 1rem;
}

.product-card-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.product-card-content p {
  color: #555;
}
.product-main {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}
/* -------------------------filter-main--------------------------- */
.filter-main-web {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50%;
}
.filter-main-mobile {
  display: none;
}
#requestform {
  margin-top: 50px;
}
/* -------------------------filtermain-web--------------------------- */
.filtermain-web-button {
  border: 2px solid aqua;
  border-radius: 10px;
  background-color: transparent;
  width: 100px;
  text-align: left;
}

.ftco-section {
  padding: 7em 0 0 0;
}

.heading-section {
  font-size: 2.5rem;
  font-weight: 700;
}

.work-wrap {
  display: flex;
  flex-direction: row;
}

.img {
  flex: 1;
  background-size: cover;
  background-position: center center;
}
.img img {
  height: 500px;
}

.text {
  flex: 1;
  padding: 2em;
  text-align: left;
}
.home-visit {
  background: #f5f5f5;
}

.sec-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
}

.sec-icon::before {
  content: "";
  position: absolute;
  height: 1px;
  left: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #f5f5f5;
  width: 50px;
}

.sec-icon::after {
  content: "";
  position: absolute;
  height: 1px;
  right: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #333;
  width: 50px;
}

.advertisers-service-sec {
  background-color: #f5f5f5;
}

.advertisers-service-sec span {
  color: rgb(255, 23, 131);
}

.advertisers-service-sec .col {
  padding: 0 1em 1em 1em;
  text-align: center;
}

.advertisers-service-sec .service-card {
  width: 340px;
  height: 300px;
  padding: 2em 1.5em;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
}

.advertisers-service-sec .service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#ffff, #2c7bfe);
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.advertisers-service-sec h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.advertisers-service-sec .icon-wrapper {
  background-color: #2c7bfe;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
  z-index: 3;
}

.advertisers-service-sec .service-card:hover:after {
  top: 0%;
}

.service-card .icon-wrapper {
  background-color: #ffffff;
  color: #2c7bfe;
}

.advertisers-service-sec .service-card:hover .icon-wrapper {
  color: black;
}

.advertisers-service-sec .service-card:hover h3 {
  color: #ffffff;
}

.advertisers-service-sec .service-card:hover p {
  color: #f0f0f0;
}
@media (max-width: 768px) {
  /* .homedescripition-card-main {
    display: flex;
    width: 80%;
    max-width: 800px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
  } */
  .img img {
    height: 300px;
  }
  .work-wrap {
    display: flex;
    flex-direction: column;
  }
  .text {
    flex: 1;
    padding: 2em;
    text-align: justify;
  }
  .text h2 {
    text-align: center;
  }
  .img {
    flex: 1;
    background-size: cover;
    background-position: center center;
    background-color: #008aff;
  }
  #requestform {
    margin-top: 130px;
  }
  #productpage {
    margin-top: 80px;
  }
  #productpage-web {
    margin-top: 130px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  /* -------------------------navbar start--------------------------- */
  /* .search-bar {
    border: 1px solid green;
  }
  .search-bar button {
    border: none;
  } */
  .site-mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
  .js-clone-nav li {
    list-style-type: none;
    margin-top: 10px;
  }
  .js-clone-nav .nav-link {
    color: #111111;
    text-decoration: none; /* Ensure links have no underline by default */
  }

  .js-clone-nav .nav-link:hover {
    color: #32dbc6;
  }
  .js-clone-nav .nav-link:active {
    color: #32dbc6;
  }
  .site-mobile-menu.active {
    display: block;
    transform: translateX(0);
  }

  .site-mobile-menu-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 50px;
  }

  .has-children > .dropdown {
    display: none;
  }
  .has-children ul {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #111111;
    width: 180px;
    padding: 10px 0px;
    text-align: center;
  }
  .has-children .dropdown-li {
    color: #111111;
  }

  .has-children .dropdown-li:hover,
  .has-children .dropdown-li:active {
    color: blue; /* Change to blue on hover or when active */
  }

  .has-children.active > .dropdown {
    display: block;
  }

  .site-menu-toggle {
    background-color: #fff;
    border: none;
  }

  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    flex-direction: column;
  }
  .header-navcontact {
    font-size: 13px;
  }
  .social-media {
    display: flex;
    float: left;
    margin-top: 3px;
  }
  .social-media li {
    margin-left: 33px;
  }
  /* -------------------------navbar end--------------------------- */
  /* -------------------------filter-main--------------------------- */
  .filter-main-web {
    display: none;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  /* -------------------------filter-mobile--------------------------- */
  .filter-main-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-main-mobile button {
    border: #292828;

    padding: 3px 20px;
    border-radius: 25px 25px;
    font-size: 20px;
    color: black;
    margin-left: 20px;
  }
}
